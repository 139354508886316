<template>
  <div>
    <button class="btn btn-primary" @click="createNewStatus">
      <PlusIcon class="feather-sm mr-1"></PlusIcon>Create
    </button>
  </div>
</template>
<script>
import PlusIcon from "../assets/plus.svg"
export default {
  components: {
    PlusIcon,
  },
  methods: {
    createNewStatus() {
      const { gridApi } = this.$store.state
      const event = new Event("createResource")
      gridApi.dispatchEvent(event)
    },
  },
}
</script>

<style></style>
